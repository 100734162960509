import { axiosConfig } from '../api/axios-config.js'

// 控制裝置
export const deviceControl = {
  // 取得任務歷史紀錄
  getTaskHistory: (id, token) => axiosConfig.get(`/api/device/${id}/task`, token),

  // 取得任務
  getSingleTask: (id, tashID, token) => axiosConfig.get(`/api/device/${id}/task/${tashID}`, token),

  // 下任務
  command: (id, data, token) => axiosConfig.post(`/api/device/${id}/task`, data, token),

  // 批量下任務
  batchCommand: (data, token) => axiosConfig.post('/api/device/batch-task', data, token)
}
