<template>
  <div class="device-control-history">
    <div class="container-fluid py-4">
      <h2 class="h4 fw-medium mb-4">
        <a href="#" @click="$router.go(-1)" class="text-dark text-decoration-none" ><i class="bi bi-arrow-left fs-4"></i>
          {{ items && items.data.length ? items.data[0].device_serial_id + $t("__deviceCommandRecord") : $t('__goBackPage') }}
        </a>
      </h2>
      <div class="d-flex align-items-center mb-3">
        <div class="device-report-select">
          <VueCtkDateTimePicker
            :label="$t('__dateSearch')"
            v-model="search"
            only-date
            format="YYYY-MM-DD"
            formatted="YYYY-MM-DD"
            color="#2CBDC0"
            button-color="#2CBDC0"
            :max-date="new Date().format('yyyy-MM-dd')"
          />
        </div>
      </div>
    </div>
    <div v-if="items && items.data.length" class="container-fluid pb-4">
      <div class="card">
        <div class="card-body" v-if="items">
          <b-table
            :responsive="$store.state.windowWidth > $store.state.breakpoints.sm"
            :stacked="$store.state.windowWidth <= $store.state.breakpoints.sm"
            borderless
            hover
            no-provider-filtering
            head-variant="light"
            :fields="fields"
            :empty-text="$t('__notData')"
            :items="items.data"
            :current-page="currentPage"
            :per-page="perPage"
          >
            <template v-slot:head()="scope">
              <div class="text-nowrap">
                {{ scope.label }}
              </div>
            </template>
            <template v-slot:cell(created_at)="data">
              {{ new Date(data.item.created_at).format("yyyy-MM-dd hh:mm") }}
            </template>
            <template v-slot:cell(updated_at)="data">
              {{ new Date(data.item.updated_at).format("yyyy-MM-dd hh:mm") }}
            </template>
            <template v-slot:cell(status)="data">
              <span :class="statusColor(data.item.status)" class="fw-medium">{{
                statusText(data.item.status)
              }}</span>
            </template>
            <template v-slot:cell(show_details)="row">
              <button
                @click="row.toggleDetails"
                type="button"
                class="btn btn-link px-1 text-dark fs-4"
              >
                <i
                  class="bi mx-1"
                  :class="row.detailsShowing ? 'bi-dash' : 'bi-plus'"
                ></i>
              </button>
            </template>
            <template v-slot:row-details="row">
              <div class="bg-light p-4">
                <div class="row align-items-center">
                  <div class="command-box col-md-auto" :class="{'w-100': row.item.task_type === 'parameter'}">
                    {{ $t('__submit') }}
                    <div class="bg-white px-3 pb-3 my-2 command-box-info">
                      <p v-if="row.item.data" :class="row.item.task_type === 'parameter' ? 'pt-3' : ''">
                        {{
                          row.item.task_type === 'parameter'
                            ? JSON.stringify(row.item.data.parameter_json, null, " ").replace(/{|}|\\/g,"")
                            : JSON.stringify(row.item.data, null, " ").replace(/{|}|\\/g,"")
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="p-4 text-center d-none d-md-block col-md" v-if="row.item.task_type !== 'parameter'">
                    <i class="bi bi-arrow-right fs-4"></i>
                  </div>
                  <div class="command-box col-auto-md" v-if="row.item.task_type !== 'parameter'">
                    {{ $t('__return') }}
                    <div class="bg-white px-3 pb-3 my-2 command-box-info">
                      <p v-if="row.item.result">
                        {{
                          JSON.stringify(row.item.result, null, " ").replace(
                            /{|}|\\/g,
                            " "
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
      <div class="row align-items-center mt-3" v-if="items">
        <div class="col-sm col-12 text-right text-sm-left">
          {{ $t('__paginationInfo', {
            startNub: items.current_page == 1
              ? 1
              : items.per_page * (items.current_page - 1) + 1,
            entNub: currentPage == 1
              ? items.total > items.per_page
                ? items.per_page
                : items.total
              : items.per_page * (items.current_page - 1) + items.per_page >
                items.total
              ? items.total
              : items.per_page * (items.current_page - 1) + items.per_page ,
            sum: items.total
            })
          }}
        </div>
        <div class="col-sm col-12">
          <b-pagination
            v-if="items.total > items.per_page"
            v-model="items.current_page"
            :total-rows="items.total"
            :per-page="items.per_page"
            align="right"
            first-number
            last-number
            @click.native="pagination(items.current_page)"
            class="mb-sm-0 mb-3 mt-sm-0 mt-3"
          ></b-pagination>
        </div>
      </div>
    </div>
    <div v-else class="container-fluid pb-4">
      <div class="h3 text-center p-5">
        {{ $t('__deviceNoRecord')}}
      </div>
    </div>
  </div>
</template>

<script>
import { Tooltip } from 'bootstrap'
import i18n from '@/lang/lang.js'
import { deviceControl } from '@/http/api/deviceControl.js'
import { mapState, mapMutations } from 'vuex'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

export default {
  name: 'DeviceControlHistory',
  components: { VueCtkDateTimePicker },
  data () {
    return {
      items: null,
      search: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      bootstrap_tooltip: null
    }
  },
  computed: {
    ...mapState(['token', 'userInfo']),
    fields () {
      return [
        {
          key: 'task_type',
          label: i18n.t('__instructionType'),
          class: 'align-middle'
        },
        {
          key: 'created_at',
          label: i18n.t('__commandTime'),
          class: 'align-middle'
        },
        {
          key: 'updated_at',
          label: i18n.t('__returnTime'),
          class: 'align-middle'
        },
        {
          key: 'status',
          label: i18n.t('__status'),
          class: 'align-middle'
        },
        {
          key: 'show_details',
          label: i18n.t('__details'),
          class: 'align-middle text-center'
        }
      ]
    }
  },
  watch: {
    search () {
      const vm = this
      vm.getDeviceControl(1)
    },
    items (data) {
      const vm = this
      if (data) {
        vm.initBootstrapTooltip()
      }
    }
  },
  methods: {
    ...mapMutations(['Loading', 'Loaded', 'AlertsInfo', 'Alerted']),
    statusText (key, startAt) {
      if (parseInt(new Date() - new Date(startAt) / 1000 / 60) > 10) {
        return i18n.t('__responseTimeout')
      }
      switch (key) {
        case 'success':
          return i18n.t('__commandHasBeenResponded')
        case 'running':
          return i18n.t('__commandHasBeenSent')
        case 'pending':
          return i18n.t('__commandNotYetSent')
      }
    },
    statusColor (key, startAt) {
      if (parseInt(new Date() - new Date(startAt) / 1000 / 60) > 10) {
        return 'text-danger'
      }
      switch (key) {
        case 'success':
          return 'text-primary'
        case 'running':
          return 'text-warning'
        case 'pending':
          return 'text-dark'
      }
    },
    getDeviceControl (page) {
      const vm = this
      var config = Object.assign({}, vm.token)
      config.params = {}
      config.params.page = page
      if (vm.search) { config.params.date = new Date(vm.search).format('yyyy-MM-dd') }
      vm.Loading()
      deviceControl.getTaskHistory(vm.$route.query.id, config).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.items = res.data.tasks
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errMsg.toString()
          })
          vm.Alerted()
        }
        vm.Loaded()
      })
    },
    clearSearch () {
      this.search = null
    },
    pagination (currentPage) {
      this.getDeviceControl(currentPage)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    initBootstrapTooltip () {
      this.$nextTick(function () {
        this.bootstrap_tooltip = [].slice
          .call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
          .map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
          })
        document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(ele => {
          ele.addEventListener('shown.bs.tooltip', function () {
            setTimeout(() => {
              Tooltip.getInstance(ele).hide()
            }, 600)
          })
        })
      })
    },
    hideTooltip () {
      if (this.bootstrap_tooltip && this.bootstrap_tooltip.length) {
        this.bootstrap_tooltip.forEach((tooltip) => {
          tooltip.hide()
        })
      }
    }
  },
  created () {
    this.getDeviceControl(1)
  },
  mounted () {}
}
</script>

<style lang="scss">
.command-box {
  white-space: pre-line;
  word-break: break-all;
  width: 43%;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
  &-info {
    height: 200px;
    overflow-y: auto;
  }
}
</style>
